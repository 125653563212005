.drawerCard {
  background: #1A1A1A;
  border-radius: 12px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
}

@media (max-width: 768px) {
  .drawerCard {
    display: none;
  }
}

.drawerCard:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
}

.logo {
  /* background: linear-gradient(135deg, #0666EB 0%, #0555c4 100%); */
  /* padding: 6px; */
  border-radius: 8px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background: white;
}

.messageContainer {
  flex-grow: 1;
  min-width: 0;
}

.message {
  font-size: 0.9rem;
  font-weight: 600;
  color: #FFFFFF;
  margin-bottom: 4px;
  line-height: 1.3;
}

.subMessage {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.3;
}

.startButton {
  width: 100%;
  color: #FFFFFF !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  margin-top: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.startButton:hover {
  background: rgba(255, 255, 255, 0.15) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
} 