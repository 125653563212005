.footer {
    background-color: #474055; /* #0081fe; */
    color: #ffffff;
    padding: 5% 10%;
  }

  @media (max-width: 768px) {
    .footer {
      margin: 0%;
    }
  }
  
  .footerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .media {
    width: 100%;
  }
  
  .column {
    /* flex: 0; */
    max-width: 500px;
  }
  
  .logo {
    width: 64px;
    height: 64px;
  }
  
  .title {
    font-weight: 700;
    font-size: 20px;
  }
  
  .text, .link, .socialText {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.75;
  }

  .text {
    margin-right: 30px;
  }
  
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .listItem, .link {
    margin: 10px 0;
    text-decoration: none;
    color: #ffffff;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .newsletter {
    display: flex;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    margin-top: 5%;
    border-bottom-color: #ffffff68;
    border-bottom-width: 2px;
    background-color: #474055;
    color: #ffffff;
  }

  .input::placeholder {
    color: #ffffff;
    opacity: 0.85; /* Firefox */
  }
  
  .submitButton {
    background-color: transparent;
    border: none;
    margin-left: 10px;
  }
  
  .submitButton img {
    width: 24px;
    height: 24px;
  }
  
  .socialContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .socialText {
    margin-right: 10px;
  }
  
  .socialLink {
    margin: 0 10px;
  }
  
  .socialLink img {
    width: 24px;
    height: 24px;
  }
  
  .bottom {
    /* text-align: center; */
    margin-top: 50px;
  }
  
  .bottomText {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  
  .anpcContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .anpcContainer img {
    transition: opacity 0.3s ease;
  }

  .anpcContainer img:hover {
    opacity: 0.8;
  }
  