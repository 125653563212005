.navDrawer {
  width: 300px;
  margin-right: 24px;
  /* margin-top: 16px; */
}

@media (min-width: 1024px) {
  .navDrawer {
    position: sticky;
    top: 80px;
    height: calc(100vh - 100px); /* Account for top bar and some padding */
  }
}

@media (max-width: 1023px) {
  .navDrawer {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
    top: 0;
  }
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: fit-content;
}

/* Profile Section */
.profileSection {
  position: relative;
  padding-bottom: 16px;
}

.profileBackground {
  height: 60px;
  background: linear-gradient(135deg, #6B46C1, #805AD5);
}

.profileLink {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 20px;
  margin-top: -30px;
  text-decoration: none;
  color: inherit;

  .profileInfo h3 {
    color: #ffff;
  }
}

.profilePhoto {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 3px solid white;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.profileLink:hover .profilePhoto {
  transform: scale(1.05);
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.profileInfo h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #1A1A1A;
}

.profileInfo span {
  font-size: 13px;
  color: #666;
}

/* Section Styling */
.section {
  padding: 16px 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

/* Company Dropdown */
.companyDropdown {
  position: relative;
}

.dropdownButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #1A1A1A;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.dropdownButton:hover {
  background: #F9FAFB;
  border-color: #6B46C1;
}

.dropdownButton span {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  color: #666;
  transition: transform 0.2s ease;
}

.chevronUp {
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 10;
}

.dropdownItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  color: #1A1A1A;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  position: relative;
}

.dropdownItem:hover {
  background: #F9FAFB;
  color: #6B46C1;
}

.dropdownItem.selected {
  background: rgba(107, 70, 193, 0.1);
  color: #6B46C1;
  font-weight: 500;
}

.dropdownItem span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
}

.dropdownButton:hover .tooltip,
.dropdownItem:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Add a small arrow to the tooltip */
.tooltip::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.addCompanyButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: none;
  color: #6B46C1;
  font-size: 14px;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.addCompanyButton:hover {
  background: #F9FAFB;
}

/* Navigation Links */
.navLink {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  color: #1A1A1A;
  text-decoration: none;
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.navLink:hover {
  background: #F9FAFB;
  color: #6B46C1;
}

/* Icons */
.icon {
  font-size: 16px;
  color: inherit;
  flex-shrink: 0;
}

/* Feedback Section */
.feedbackSection {
  padding: 16px 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.feedbackButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  background: linear-gradient(135deg, #F6E05E, #ECC94B);
  border: none;
  border-radius: 8px;
  color: #744210;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.feedbackButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(236, 201, 75, 0.3);
}

.feedbackButton:active {
  transform: translateY(1px);
}

.subscriptionInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.proBadge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background: linear-gradient(135deg, #6B46C1, #805AD5);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
}

.crownIcon {
  font-size: 10px;
  color: #FFD700;
}

.upgradeBadge {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.upgradeText {
  color: #6B46C1;
  font-weight: 600;
  text-decoration: underline;
}