.cardContainer {
  max-width: 680px;
  margin-bottom: 1.5rem;
  background: #000000;
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.cardContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.25);
}

.content {
  padding: 32px 32px 24px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.logoContainer {
  flex-shrink: 0;
}

.logo {
  width: 64px;
  height: 64px;
  border-radius: 16px;
}

.messageContainer {
  flex-grow: 1;
}

.message {
  font-size: 1.25rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1.4;
  margin-bottom: 8px;
}

.subMessage {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.startButton {
  width: 100%;
  color: #FFFFFF !important;
  font-weight: 600;
  padding: 16px 24px;
  border-radius: 0;
  transition: all 0.2s ease;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.startButton:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #FFFFFF !important;
}

.startButton .arrowIcon {
  color: #FFFFFF !important;
  transform: rotate(180deg);
}

.startButton:hover .arrowIcon {
  transform: rotate(180deg) translateX(4px);
}

.arrowIcon {
  transition: transform 0.2s ease;
}

/* Grid view styles */
.gridView {
  width: 100%;
  margin: 0.5rem;
  min-width: 300px;
}

.gridView .content {
  flex-direction: column;
  text-align: center;
  padding: 24px 24px 16px;
}

.gridView .message {
  font-size: 1.1rem;
}

.gridView .subMessage {
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    text-align: center;
    padding: 24px 24px 16px;
  }

  .message {
    font-size: 1.1rem;
  }

  .subMessage {
    font-size: 0.9rem;
  }
} 