.cardContainer {
  max-width: 680px;
  transition: all 0.3s ease;
  overflow: hidden;
  margin-bottom: 1.5rem;
  background: linear-gradient(145deg, #002395 0%, #001875 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: relative;
}

.cardContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.header {
  position: relative;
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.headerContent {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.gridView {
  width: 100%;
  margin: 0.5rem;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
}

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px 24px;
  margin-top: auto;
}

/* Benefit item hover effect */
.benefitItem {
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.benefitItem:hover {
  transform: translateX(5px);
  background: rgba(255, 255, 255, 0.15) !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Grid view specific styles */
.cardContainer.gridView {
  max-width: 100%;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gridView .header {
  padding: 16px;
}

.gridView .headerContent {
  gap: 12px;
}

.gridView .headerContent > div {
  font-size: 16px;
}

.gridView .body {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gridView .footer {
  padding: 12px;
  margin-top: auto;
}

.gridView .footerActions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-direction: row;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cardContainer {
    margin: 1rem 0;
  }
  
  .gridView {
    max-width: 100%;
    margin: 1rem 0;
  }

  .header, .body {
    padding: 16px;
  }

  .footerActions {
    flex-direction: row;
    gap: 8px;
  }
}

@media (min-width: 1400px) {
  .gridView {
    min-width: 350px;
    max-width: none;
  }
} 